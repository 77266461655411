import React from "react";
import Layout from "../components/layout";

const Privacy = () => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Adatvédelmi tájékoztató</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>1. A szabályzat célje</h2>
          <p>
            A jelen Szabályzat célja, hogy rögzítse a PE-VA Műhelye Kft. (1013
            Budapest, Pauler utca 8. cégjegyzékszám: 01-09-950747, nyilvántartó
            hatóság: Budapest Főváros 1. kerület Budavári Polgármesteri Hivatal)
            által alkalmazott adatvédelmi és adatkezelési elveket és a Társaság
            adatvédelmi és adatkezelési politikáját.
          </p>
          <p>
            A PE-VA Műhelye Kft. (a továbbiakban: Szolgáltató) a{" "}
            <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a> (a
            továbbiakban: Weboldal) működtetése során a Honlapra látogatók, a
            Honlapon regisztrálók, illetve azon vásárlók, (a továbbiakban
            együttesen Érintett) adatait kezeli.
          </p>
          <p>
            A jelen Szabályzat célja, hogy a Szolgáltató által nyújtott
            szolgáltatások minden területén, minden egyén számára, tekintet
            nélkül nemzetiségére vagy lakóhelyére, biztosítva legyen, hogy
            jogait és alapvető szabadságjogait, különösen a magánélethez való
            jogát tiszteletben tartsák a személyes adatainak gépi feldolgozása
            során (adatvédelem).
          </p>

          <h2>2. A szolgáltató mint adatkezelő megnevezése</h2>
          <p>Név: PE-VA Műhelye Kft.</p>
          <p>Székhely és levelezési cím: 1013 Budapest, Pauler utca 8.</p>
          <p>Telefonszám: +36 30 875 90 79</p>
          <p>Email cím: info@szelencecafe.hu</p>

          <h2>3. A kezelt személyes adatok köre</h2>
          <h3>3.1 Regisztráció (jelentkezés programra, tortarendeléshez)</h3>
          <p>
            A regisztráció során az Érintettnek kötelezően meg kell adnia a
            következő személyes adatokat.
          </p>
          <ul>
            <li>név</li>
            <li>saját e-mail cím</li>
            <li>telefonszám</li>
          </ul>

          <h3>3.2 Technikai adatok</h3>
          <p>
            A rendszer működtetése során technikailag rögzítésre kerülő adatok:
            az Érintett bejelentkező számítógépének azon adatai, melyek a
            szolgáltatás igénybe vétele során generálódnak és melyeket a
            Szolgáltató rendszere a technikai folyamatok automatikus
            eredményeként rögzít. Az automatikusan rögzítésre kerülő adatokat a
            rendszer az Érintett külön nyilatkozata vagy cselekménye nélkül a
            belépéskor, illetve kilépéskor automatikusan naplózza. Ezen adatok
            egyéb személyes felhasználói adatokkal - törvény által kötelezővé
            tett esetek kivételével - össze nem kapcsolhatók. Az adatokhoz
            kizárólag a Szolgáltató fér hozzá.
          </p>

          <h3>3.3 Cookie</h3>
          <p>
            A Weboldalon tett látogatások során egy vagy több cookie-t – azaz
            egy-egy karaktersorozatot tartalmazó kis fájlt – küld a Szolgáltató
            a látogató számítógépére, amely(ek) révén annak böngészője egyedileg
            azonosítható lesz. Ezen cookie-k a Google által biztosítottak,
            felhasználásuk a Google Adwords rendszerén keresztül történik.
            Ezeket a cookie-kat csak egyes al-oldalak látogatása esetén küldjük
            el a látogató számítógépére, tehát ezekben csak az adott al-oldal
            meglátogatásának tényét és idejét tároljuk, semmilyen más
            információt nem.
          </p>
          <p>
            Az így elküldött cookie-k felhasználása a következő: A külső
            szolgáltatók, közöttük a Google, ezen cookie-k segítségével
            tárolják, ha az Érintett korábban már látogatást tett a hirdető
            webhelyén, és ez alapján hirdetéseket jelenítenek meg az Érintett
            részére külső szolgáltatók - közöttük a Google - partnereinek
            internetes webhelyein. Az Érintett a Google hirdetések
            kikapcsolására szolgáló oldalon tilthatják le a Google cookie-jait.
          </p>
          <p>
            A <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a> a
            Google Adwords és a Facebook remarketing követő kódjait használja.
            Ennek alapja, hogy az oldalra látogatókat később remarketing
            hirdetésekkel kereshessük fel. A remarketing kód cookie-kat használ,
            a látogatók megcímkézéséhez. A{" "}
            <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a>{" "}
            oldalt felkeresők letilthatják ezeket a cookie-kat. A letiltást
            követően a számukra nem fognak megjelenni személyre szabott
            ajánlatok a Szolgáltatótól.
          </p>

          <h4>Alkalmazott cookie-k</h4>
          <p>
            Átmeneti (session) cookie: a session cookie-k az Érintett látogatása
            után automatikusan törlődnek. Ezek a cookie-k arra szolgálnak, hogy
            a Szolgáltató Honlapja hatékonyabban és biztonságosabban tudjon
            működni, tehát elengedhetetlenek ahhoz, hogy a Honlap egyes funkciói
            vagy egyes alkalmazások megfelelően tudjanak működni.
          </p>
          <p>
            Állandó (persistent) cookie: állandó cookie-t is használ a
            Szolgáltató a jobb felhasználói élmény érdekében (pl. optimalizált
            navigáció nyújtása). Ezek a cookie-k hosszabb ideig kerülnek
            tárolásra a böngésző cookie file-jában. Ennek időtartama attól függ,
            hogy az Érintett az internetes böngészőjében milyen beállítást
            alkalmaz.
          </p>
          <p>
            Külső szerverek segítik a Honlap látogatottsági és egyéb
            webanalitikai adatainak független mérését és auditálását (Google
            Analytics). A mérési adatok kezeléséről az adatkezelők tudnak
            részletes felvilágosítást nyújtani az Érintett részére.
            Elérhetőségük:{" "}
            <a href="www.google.com/analytics">www.google.com/analytics</a>{" "}
            Amennyiben az Érintett nem szeretné, hogy a Google Analytics a fenti
            adatokat az ismertetett módon és céllal mérje, telepítse
            böngészőjébe az ezt blokkoló kiegészítőt.
          </p>
          <p>
            A legtöbb böngésző menüsorában található „Segítség” funkció
            tájékoztatást nyújt arra vonatkozóan, hogy a böngészőben hogyan
            lehet letiltani a cookie-kat, hogyan fogadjon el új cookie-kat, vagy
            hogyan adjon utasítást böngészőjének arra, hogy új cookie-t állítson
            be, vagy hogyan kapcsoljon ki egyéb cookie-kat.
          </p>

          <h3>3.4 Online rendeléssel kapcsolatos általános adatkezelés</h3>
          <p>
            A Szolgáltató fenntartja a jogot arra, hogy az érintett
            beleegyezését követően az Info. tv.-ben meghatározott módon, a
            weboldalon leadott rendeléskor/jelentkezéskor a számlakiállításhoz
            szükséges természetes személy adatait a Számlákon rögzítse. Az Info.
            tv. rendelkezései értelmében a hozzájárulás, szóban, írásban és
            ráutaló magatartással (jelölőnégyzet kipipálása) is megadható. Jelen
            adatkezelés célja a vásárlók tulajdonjogához fűződő érdekének
            megóvása. Az Info. tv. rendelkezései értelmében az érintett kérheti
            a Szolgáltatótól a jelen pontban rögzített személyes adatának
            törlését.
          </p>

          <h3>3.5 Hírlevéllel kapcsolatos adatkezelés</h3>
          <p>
            A <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a>{" "}
            oldalon személyre szabott hírlevélre történő feliratkozásra van
            lehetőség. A lehetőség igénybevételével kapcsolatosan a hírlevélre
            feliratkozó felhasználó önkéntes adatszolgáltatás keretében a
            lentebb meghatározott személyes adatait adja át kezelésre a PE-VA
            Műhelye Kft. részére:
          </p>
          <ul>
            <li>név</li>
            <li>email cím</li>
          </ul>

          <h2>4. Az adatkezelés jogalapja, célja és módja</h2>
          <p>
            4.1 Az adatkezelésre a{" "}
            <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a>{" "}
            internetes oldalon (a továbbiakban: Weboldal) található internetes
            tartalmak az Érintett önkéntes, megfelelő tájékoztatáson alapuló
            nyilatkozata alapján kerül sor, amely nyilatkozat tartalmazza az
            Érintett kifejezett hozzájárulását ahhoz, hogy az Weboldal
            használata során közölt személyes adataik felhasználásra kerüljenek.
            Az adatkezelés jogalapja az információs önrendelkezési jogról és az
            információszabadságról szóló 2011. évi CXII. tv. (Infotv.) 5.§ (1)
            bek. a) pontja szerint az érintett önkéntes hozzájárulása alapján,
            valamint az elektronikus kereskedelmi szolgáltatások, valamint az
            információs társadalommal összefüggő szolgáltatások egyes
            kérdéseiről szóló 2001. évi CVIII. törvény alapján kerül sor.
          </p>
          <p>
            A hozzájárulást az Érintett az egyes adatkezelések tekintetében a
            Weboldal használatával, a regisztrációval, illetve a kérdéses adatok
            önkéntes megadásával adja meg.
          </p>
          <p>
            4.2 Az adatkezelés célja a Weboldalon elérhető szolgáltatások
            nyújtásának biztosítása. A Szolgáltató az Érintett által
            rendelkezésre bocsátott adatokat célhoz kötötten, kizárólag a
            megrendelés teljesítése, számlázás lehetővé tétele, kapcsolattartás,
            illetve ha az Érintett feliratkozott hírlevélre, akkor hírlevél
            küldés és az esetlegesen létrejövő szerződés feltételeinek későbbi
            bizonyítása érdekében.
          </p>
          <p>
            4.3 Az automatikusan rögzítésre kerülő adatok célja
            statisztikakészítés, az informatikai rendszer technikai fejlesztése,
            az Érintett jogainak védelme.
          </p>
          <p>
            4.4 A Szolgáltató a megadott személyes adatokat az e pontokban írt
            céloktól eltérő célokra nem használja illetve használhatja fel.
            Személyes adatok harmadik személynek vagy hatóságok számára történő
            kiadása - hacsak törvény ettől eltérően nem rendelkezik kötelező
            erővel - az Érintett előzetes, kifejezett hozzájárulása esetén
            lehetséges kizárólag.
          </p>
          <p>
            4.5 Szolgáltató a neki megadott személyes adatokat nem ellenőrzi. A
            megadott adatok megfelelősségéért kizárólag az azt megadó személy
            felel. Bármely Érintett e-mail címének megadásakor egyben
            felelősséget vállal azért, hogy a megadott e-mail címről kizárólag ő
            vesz igénybe szolgáltatást. E felelősségvállalásra tekintettel egy
            megadott e-mail címen történt belépésekkel összefüggő mindennemű
            felelősség kizárólag azt az Érintett terheli, aki az e-mail címet
            regisztrálta.
          </p>

          <h2>5. Az adatkezelés időtartama</h2>
          <p>
            5.1 A regisztráció során megadott személyes adatok kezelése a
            regisztrációval kezdődik és annak kérelemre történő törléséig tart.
            Nem kötelező adatok esetén az adatkezelés az adat megadásának
            időpontjától a kérdéses adat kérelemre történő törléséig tart. A
            regisztráció Érintett általi törlésére, valamint Szolgáltató általi
            törlésére bármikor, az Általános Szerződési Feltételekben (a
            továbbiakban: ÁSZF) rögzített esetben és módon kerülhet sor.
          </p>
          <p>
            5.2 A naplózott adatokat a rendszer – az utolsó látogatás dátumának
            kivételével, amelyet automatikusan felülír – a naplózás időpontjától
            számított 6 hónapig tárolja.
          </p>
          <p>
            5.3 Fenti rendelkezések nem érintik a jogszabályban (pl. számviteli
            jogszabályokban) meghatározott megőrzési kötelezettségek
            teljesítését, valamint a Weboldalon történő regisztráció során vagy
            egyéb módon adott további hozzájárulások alapján történő
            adatkezeléseket.
          </p>
          <p>
            5.4 Hírlevél esetén a személyes adatok kezelése a hírlevélről, a
            8.4. pontban meghatározott módon történő leiratkozásig tart.
          </p>

          <h2>
            6. Az adatokat megismerő személyek köre, adattovábbítás,
            adatfeldolgozás
          </h2>
          <p>
            6.1 Az adatokat elsődlegesen Szolgáltató illetve Szolgáltató belső
            munkatársai jogosultak megismerni, azonban azokat nem teszik közzé,
            harmadik személyek részére nem adják át.
          </p>
          <p>
            6. 2Az alapul fekvő informatikai rendszer üzemeltetése, a
            megrendelések teljesítése, az elszámolás rendezése körében
            Szolgáltató adatfeldolgozót (pl. rendszerüzemeltető, fuvarozó
            vállalat, könyvelő) vehet igénybe.
          </p>
          <p>
            6.3 A PE-VA Műhelye Kft. az Érintettek személyes adatait nyomon
            követhető módon és a jogszabályoknak megfelelően kezeli. A fentieken
            túl a személyes adatokat harmadik személyeknek csak és kizárólag az
            Érintett hozzájárulása esetén továbbítjuk, és csak hatósági
            megkeresések esetén adjuk ki.
          </p>

          <h2>7. Érintett jogai és jogérvényesítési lehetőségek</h2>
          <p>
            7.1 Az Érintett bármikor jogosult tájékoztatást kérni a Szolgáltató
            által kezelt, rá vonatkozó személyes adatokról, továbbá bármikor
            módosíthatja azokat az ÁSZF-ben meghatározott módon.
          </p>
          <p>
            7.2 Szolgáltató az Érintett kérésére tájékoztatást ad a rá
            vonatkozó, általa kezelt adatokról, az általa vagy rendelkezése
            szerint megbízott adatfeldolgozó által feldolgozott adatairól, azok
            forrásáról, az adatkezelés céljáról, jogalapjáról, időtartamáról,
            továbbá az adatfeldolgozó nevéről, címéről és az adatkezeléssel
            összefüggő tevékenységéről, az adatvédelmi incidens körülményeiről,
            hatásairól és az elhárítására megtett intézkedésekről, továbbá - az
            Érintett személyes adatainak továbbítása esetén - az adattovábbítás
            jogalapjáról és címzettjéről. Szolgáltató a kérelem benyújtásától
            számított 30 napon belül írásban adja meg a kért tájékoztatást.
          </p>
          <p>
            A Szolgáltató - ha belső adatvédelmi felelőssel rendelkezik, a belső
            adatvédelmi felelős útján - az adatvédelmi incidenssel kapcsolatos
            intézkedések ellenőrzése, valamint az Érintett tájékoztatása
            céljából nyilvántartást vezet, amely tartalmazza az Érintett
            személyes adatok körét, az adatvédelmi incidenssel Érintettek körét
            és számát, az adatvédelmi incidens időpontját, körülményeit,
            hatásait és az elhárítására megtett intézkedéseket, valamint az
            adatkezelést előíró jogszabályban meghatározott egyéb adatokat.
          </p>
          <p>
            7.3 Az érintett a jogait az alábbi elérhetőségeken gyakorolhatja:
          </p>
          <ul>
            <li>Levelezési cím: 1013 Budapest, Pauler utca 8. </li>
            <li>Ügyfélszolgálat: info@szelencecafe.hu </li>
          </ul>
          <p>
            Az Érintett bármely, az adatkezeléssel kapcsolatos kérdéssel,
            illetve észrevétellel a Szolgáltató munkatársához fordulhat a 7.3.
            pontban elérhetőségeken keresztül.
          </p>
          <p>
            7.4. Az Érintett bármikor jogosult a helytelenül rögzített adatainak
            helyesbítését vagy azok törlését kérni. Egyes adatait az Érintett a
            Weboldalon maga helyesbítheti; egyéb esetekben Szolgáltató a kérelem
            beérkezésétől számított 3 munkanapon belül törli az adatokat, ez
            esetben azok nem lesznek újra helyreállíthatók. A törlés nem
            vonatkozik a jogszabály (pl. számviteli szabályozás) alapján
            szükséges adatkezelésekre, azokat Szolgáltató a szükséges
            időtartamig megőrzi.
          </p>
          <p>
            7.5. Az Érintett kérheti továbbá, adatainak zárolását. A Szolgáltató
            zárolja a személyes adatot, ha az érintett ezt kéri, vagy ha a
            rendelkezésére álló információk alapján feltételezhető, hogy a
            törlés sértené az Érintett jogos érdekeit. Az így zárolt személyes
            adat kizárólag addig kezelhető, ameddig fennáll az adatkezelési cél,
            amely a személyes adat törlését kizárta.
          </p>
          <p>
            A helyesbítésről, a zárolásról és a törlésről az Érintettet, továbbá
            mindazokat értesíteni kell, akiknek korábban az adatot adatkezelés
            céljára továbbították. Az értesítés mellőzhető, ha ez az adatkezelés
            céljára való tekintettel az Érintett jogos érdekét nem sérti.
          </p>
          <p>
            Ha a Szolgáltató az Érintett helyesbítés, zárolás vagy törlés iránti
            kérelmét nem teljesíti, a kérelem kézhezvételét követő 30 napon
            belül írásban közli a helyesbítés, zárolás vagy törlés iránti
            kérelem elutasításának ténybeli és jogi indokait.
          </p>
          <p>
            Az Érintett tiltakozhat személyes adatának kezelése ellen.
            Szolgáltató a tiltakozást a kérelem benyújtásától számított
            legrövidebb időn belül, de legfeljebb 15 nap alatt megvizsgálja,
            annak megalapozottsága kérdésében döntést hoz, és döntéséről a
            kérelmezőt írásban tájékoztatja.
          </p>
          <p>
            7.6. Az Érintett az Info.tv., valamint a Polgári Törvénykönyv (2013.
            évi V. törvény) alapján a Nemzeti Adatvédelmi és Információszabadság
            Hatósághoz (1125 Budapest, Szilágyi Erzsébet fasor 22/c.;
            www.naih.hu) fordulhat.
          </p>
          <p>
            7.7. Amennyiben az Érintett szolgáltatás igénybevételéhez a
            regisztráció során harmadik fél adatait adta meg vagy a Weboldal
            használata során bármilyen módon kárt okozott, a Szolgáltató
            jogosult az Érintettel szembeni kártérítés érvényesítésére. A
            Szolgáltató ilyen esetben minden tőle telhető segítséget megad az
            eljáró hatóságoknak a jogsértő személy személyazonosságának
            megállapítása céljából.
          </p>

          <h2>8. Hírlevél, személyre szabott hírlevél</h2>
          <p>
            8.1. A Szolgáltató kiemelt figyelmet fordít az általa kezelt
            elektronikus levélcímek felhasználásnak jogszerűségére, így azokat
            csak az alábbiakban meghatározott módon használja fel (tájékoztató
            vagy reklám) e-mail küldésére.
          </p>
          <p>
            8.2. Az e-mail címek kezelése elsősorban az Érintett azonosítását, a
            megrendelések teljesítése, szolgáltatások igénybe vétele során a
            kapcsolattartást szolgálja, így elsősorban ennek érdekében kerül sor
            e-mail küldésére.
          </p>
          <p>
            8.3. A Szolgáltató által nyújtott szolgáltatások vagy az ÁSZF
            változása esetén a változásokra vonatkozó, valamint a Szolgáltató
            egyéb hasonló szolgáltatásaira vonatkozó tájékoztatást a Szolgáltató
            bizonyos esetekben elektronikus formában, e-mailben juttatja el az
            Érintettek részére. Az ilyen értesítéseket azonban a Szolgáltató nem
            használja reklámozási célra.
          </p>
          <h3>8.4. Hírlevél</h3>
          <p>
            A regisztráció során megadott elektronikus levélcímekre hirdetést
            vagy reklámot (hírlevél) tartalmazó leveleket a Szolgáltató csak az
            Érintett kifejezett hozzájárulásával, a jogszabályi előírásoknak
            megfelelő esetekben és módon küld. A hírlevél direkt marketing
            elemekkel rendelkezik és reklámot tartalmaz. Szolgáltató a hírlevél
            igénybevétele során az Érintett által megadott adatokat kezeli.
          </p>
          <p>
            Hírlevél esetében a Szolgáltató, mindaddig kezeli az Érintett
            hírlevél feliratkozása során megadott adatait, ameddig az Érintett a
            hírlevélről le nem iratkozik a hírlevél alján található
            „Leiratkozás” gombra kattintva vagy nem kéri a levételét a
            hírlevélre feliratkozók listájából emailben vagy postai úton.
            Leiratkozás esetén a Szolgáltató további hírleveleivel, ajánlataival
            nem keresi meg az Érintettet. Az Érintett bármikor ingyenesen
            leiratkozhat a hírlevélről és hozzájárulását visszavonhatja. A
            regisztrált felhasználóink a személyes oldalukra belépve is bármikor
            és ingyenes leiratkozhatnak a hírlevélről.
          </p>
          <h3>8.5. Személyre szabott hírlevél</h3>
          <p>
            A <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a> a
            felhasználók személyes adatait arra is felhasználhatja, hogy
            számukra személyes ajánlatokat küldjön, hírlevél formájában. A
            személyre szabott hírlevél keretében a{" "}
            <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a> a
            regisztrált és a hírlevélre feliratkozott felhasználók korábbi
            vásárlásait vizsgálja, és ennek vizsgálata és eredménye alapján,
            személyre szabott hírlevelet is küldhet a felhasználói számára.
          </p>
          <p>
            A személyre szabott hírlevélről való leiratkozásra a 8.4. pontban
            található rendelkezések az irányadóak. Amennyiben az érintett
            felhasználó leiratkozik a{" "}
            <a href="https://szelencecafe.hu/">https://szelencecafe.hu/</a>{" "}
            hírleveléről, a továbbiakban Személyre szabott hírlevelet sem fog
            kapni.
          </p>

          <h2>9. Egyéb rendelkezések</h2>
          <p>
            9.1. Szolgáltató rendszere az Érintettek aktivitásáról adatokat
            gyűjthet, melyek nem kapcsolhatóak össze az Érintett által a
            regisztrációkor megadott egyéb adatokkal, sem más honlapok vagy
            szolgáltatások igénybevételekor keletkező adatokkal.
          </p>
          <p>
            9.2. Minden olyan esetben, ha a szolgáltatott adatokat a Szolgáltató
            az eredeti adatfelvétel céljától eltérő célra kívánja felhasználni,
            erről az Érintettet tájékoztatja, és ehhez előzetes, kifejezett
            hozzájárulását megszerzi, illetőleg lehetőséget biztosít számára,
            hogy a felhasználást megtiltsa.
          </p>
          <p>
            9.3. Szolgáltató kötelezi magát, hogy gondoskodik az adatok
            biztonságáról, megteszi továbbá azokat a technikai intézkedéseket,
            amelyek biztosítják, hogy a felvett, tárolt, illetve kezelt adatok
            védettek legyenek, illetőleg mindent megtesz annak érdekében, hogy
            megakadályozza azok megsemmisülését, jogosulatlan felhasználását és
            jogosulatlan megváltoztatását. Kötelezi magát arra is, hogy minden
            olyan harmadik felet, akiknek az adatokat esetlegesen továbbítja
            vagy átadja, ugyancsak felhívja ez irányú kötelezettségeinek
            teljesítésére.
          </p>
          <p>
            9.4. Szolgáltató fenntartja a jogot, hogy jelen Szabályzatot az
            Érintettek Honlap felületén történő előzetes értesítése mellett
            egyoldalúan módosítsa. A módosítás hatályba lépését követően a z
            Érintettnek a Honlap további használatához el kell fogadnia a
            módosításokat, a Szolgáltató által, a Honlapon biztosított módon.
          </p>
          <p>Közzétéve: 2019. február 20.</p>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <title>Szelence Café</title>;

export default Privacy;
